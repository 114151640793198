import React, { useEffect, useState } from 'react'
import Footer from '../superAdmin/footer/Footer'
import Select from "react-select";
import DatePickers from '../../common/DatePickers';
import { message } from "antd";
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from 'moment';
// Function to convert a date string to ISO 8601 format
const convertToISO = (dateStr) => {
  // Parse the date string to a Date object
  const date = new Date(dateStr);
  // Convert to ISO 8601 format with time set to 00:00:00
  return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
  // Define the start and end dates in ISO format
  const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
  const endDate = new Date(process.env.REACT_APP_END_DATE);

  // Convert the given date to ISO format and create a Date object
  const givenDate = new Date(convertToISO(date));

  // Perform the date range check
  return givenDate >= startDate && givenDate <= endDate;
};

function getNextDayFormatted(date) {
  return moment(date).add(1, 'days').format('lll');
}
// Example usage
const inputDate = "Nov 14, 2024";
const isValid = isDateInRange(inputDate);

console.log(isValid); // Output: false (since Nov 14, 2024 is not in the range)


const BuyerRegistrationForm = () => {
  const designationArray = ["Designation",
    "Director",
    "Partner",
    "Proprietor",
    "Managing Director",
    "COO",
    "Owner",
    "Manager Sales",
    "M D",
    "Senior Manager", 'others'];
  const [representative_list, setRepresentative_list] = useState([
    "Akhil",
    "Kapil Ladiwal",
    "Nisha",
    "Arpan",
    "Arpan",
    "Venkat",
    "Aarpan",
    "Abhishek Jain",
    "Sanjay",
    "Mr.Venkatasayee",
    "Venkatasayee. B",
    "Tanmay Das",
    "Sonu Vats",
    "T Das",
    "Pradnya H",
    "Kapil Ji",
    "Shrey Kamdar",
    "Bhavin Z",
    "Mr. T. Das",
    "Venkatesh Iyer",
    "Kapil",
    "Karan",
    "Akil Aravind",
    "Venkatasayee Balasunderam",
    "Sonu",
    "Venkatasayee",
    "Nitin",
    "Arjun Mistry",
    "Mr.Venkat",
    "Umesh",
    "Meenakshi Jain",
    "Akhil Aravind",
    "Akhl"
  ])
  const initialFormData = {
    representative: '',
    companyName: '',
    roomNo: '',
    personName: '',
    mobileNumber: '',
    emailAddress: '',
    designation: '',
    country: '',
    alloted_car: '',
    roomOccupancy: '',
    ticketsDepartureBookedBy: 'IGI',
    ticketsReturnBookedBy: 'IGI',
    ticketsDepartureBookedBy2nd: 'IGI',
    ticketsReturnBookedBy2nd: 'IGI',
    travelMode: '',
    travelMode2nd: '',
    travelReturnMode: '',
    travelReturnMode2nd: '',
    departurePNR: '',
    otherDesignation: '',
    departureCity: '',
    departureDate: '',
    flightTrainName: '',
    flightTrainNumber: '',
    arrivalTimeGoa: '',
    goaAirport: '',
    returnPNR: '',
    returnCity: '',
    returnFlightDate: '',
    returnFlightName: '',
    returnFlightNumber: '',
    returnFlightDepartureTime: '',
    returnAirport: '',
    personName2nd: '',
    mobileNumber2nd: '',
    emailAddress2nd: '',
    designation2nd: '',
    country2nd: '',
    alloted_car2nd: '',
    roomOccupancy2nd: '',
    departurePNR2nd: '',
    otherDesignation2nd: '',
    departureCity2nd: '',
    departureDate2nd: '',
    flightTrainName2nd: '',
    flightTrainNumber2nd: '',
    arrivalTimeGoa2nd: '',
    goaAirport2nd: '',
    returnPNR2nd: '',
    returnCity2nd: '',
    returnFlightDate2nd: '',
    returnFlightName2nd: '',
    returnFlightNumber2nd: '',
    returnFlightDepartureTime2nd: '',
    returnAirport2nd: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [hotelCheckInDate, setHotelCheckInDate] = useState(null)
  const [hotelCheckOutDate, setHotelCheckOutDate] = useState(null)
  const [departureDate, setDepartureDate] = useState(null)
  const [returnFlightDate, setReturnFlightDate] = useState(null)
  const [hotelCheckInDate2nd, setHotelCheckInDate2nd] = useState(null)
  const [hotelCheckOutDate2nd, setHotelCheckOutDate2nd] = useState(null)
  const [departureDate2nd, setDepartureDate2nd] = useState(null)
  const [returnFlightDate2nd, setReturnFlightDate2nd] = useState(null)
  const urlPath = window.location.pathname; // This will return '/buyer-registration-form'
  const extractedPart = urlPath.split('/').pop(); // This will return 'buyer-registration-form'
  const [travelModerarrivalArray2nd, setTravelModerarrivalArray2nd] = useState(["Train", "Flight", "Self"])
  const [travelModerarrivalArray, setTravelModerarrivalArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray, setTravelModerDepartureArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray2nd, setTravelModerDepartureArray2nd] = useState(["Train", "Flight", "Self"])
  const [isChecked1st, setIsChecked1st] = useState(false);
  const [isChecked2nd, setIsChecked2nd] = useState(true);
  const [isChecked3rd, setIsChecked3rd] = useState(true);
  const [showBlock2nd, setShowBlock2nd] = useState(false)
  const [showBlock3rd, setShowBlock3rd] = useState(false)
  const [showBlock4th, setShowBlock4th] = useState(false)
  const [showBlock5th, setShowBlock5th] = useState(false)
  const [showBlock6th, setShowBlock6th] = useState(false)


  // console.log(extractedPart);
  const handleSelectChange = (selectedOption, object) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    if (object === "2nd") {
      if (selectedValue !== "others") {
        setFormData({ ...formData, designation2nd: selectedValue, otherDesignation2nd: "" });
      } else {
        setFormData({ ...formData, designation2nd: "others" });
      }
    } else {

      if (selectedValue !== "others") {
        setFormData({ ...formData, designation: selectedValue, otherDesignation: "" });
      } else {
        setFormData({ ...formData, designation: "others" });
      }
    }
  };

  const handleOtherDesignationChange = (event, object) => {
    const value = event.target.value;
    if (object === "2nd") {
      setFormData({ ...formData, otherDesignation2nd: value });

    } else {

      setFormData({ ...formData, otherDesignation: value });
    }
  };
  // Create a mapping object for field names to display names
  const fieldDisplayNames = {
    representative: 'Representative',
    companyName: 'Company Name',
    roomNo: 'Room Number',
    roomOccupancy: 'Room Occupancy',
    personName: 'Person Name',
    emailAddress: 'Email Address',
    mobileNumber: 'Mobile Number',
    designation: 'Designation',
    hotelCheckInDate: 'Hotel Check-In Date',
    hotelCheckOutDate: 'Hotel Check-Out Date',
    ticketsBookedBy: 'Tickets Booked By',
    travelMode: 'Travel Mode',
    travelReturnMode: 'Return Travel Mode',

    departurePNR: 'Departure PNR',
    departureCity: 'Departure City',
    departureDate: 'Departure Date',
    flightTrainName: 'Flight/Train Name',
    flightTrainNumber: 'Flight/Train Number',
    arrivalTimeGoa: 'Arrival Time in Goa',
    goaAirport: 'Goa Airport',
    returnPNR: 'Return PNR',
    returnCity: 'Return City',
    returnFlightDate: 'Return Flight Date',
    returnFlightName: 'Return Flight Name',
    returnFlightNumber: 'Return Flight Number',
    returnFlightDepartureTime: 'Return Flight Departure Time',
    returnAirport: 'Return Airport of 2nd person',
    personName2nd: 'Person Name of 2nd person',
    emailAddress2nd: 'Email Address of 2nd person',
    mobileNumber2nd: 'Mobile Number of 2nd person',
    designation2nd: 'Designation of 2nd person',
    hotelCheckInDate2nd: 'Hotel Check-In Date of 2nd person',
    hotelCheckOutDate2nd: 'Hotel Check-Out Date of 2nd person',
    ticketsBookedBy2nd: 'Tickets Booked By of 2nd person',
    travelMode2nd: 'Travel Mode of 2nd person',
    travelReturnMode2nd: 'Return Travel Mode 2nd person',

    departurePNR2nd: 'Departure PNR of 2nd person',
    departureCity2nd: 'Departure City of 2nd person',
    departureDate2nd: 'Departure Date of 2nd person',
    flightTrainName2nd: 'Flight/Train Name of 2nd person',
    flightTrainNumber2nd: 'Flight/Train Number of 2nd person',
    arrivalTimeGoa2nd: 'Arrival Time in Goa of 2nd person',
    goaAirport2nd: 'Goa Airport of 2nd person',
    returnPNR2nd: 'Return PNR of 2nd person',
    returnCity2nd: 'Return City of 2nd person',
    returnFlightDate2nd: 'Return Flight Date of 2nd person',
    returnFlightName2nd: 'Return Flight Name of 2nd person',
    returnFlightNumber2nd: 'Return Flight Number of 2nd person',
    returnFlightDepartureTime2nd: 'Return Flight Departure Time of 2nd person',
    returnAirport2nd: 'Return Airport of 2nd person'

  };

  const ticket_by = ["IGI", "Self"]
  const occupancy_list = ["Single", "Double"]
  const airportListOfGoa = ["Dabolim Airport and Manohar International Airport", "Mopa Airport"]
  // Array of required fields
  const requiredFields = [
    'representative',
    'companyName',
    'personName',
    'mobileNumber',
    'designation',
    'travelMode',
    'roomOccupancy',
    // 'departurePNR',
    // 'departureCity',
    // 'flightTrainName',
    // 'flightTrainNumber',
    // 'arrivalTimeGoa',
    // 'goaAirport',
    // 'returnPNR',
    // 'returnCity',
    // 'returnFlightName',
    // 'returnFlightNumber',
    // 'returnFlightDepartureTime',
    // 'returnAirport',


  ];

  const requiredforSecondFields = [
    'personName2nd',
    'mobileNumber2nd',
    'designation2nd',
    // 'departurePNR2nd',
    // 'departureCity2nd',
    // 'flightTrainName2nd',
    // 'flightTrainNumber2nd',
    // 'arrivalTimeGoa2nd',
    // 'goaAirport2nd',
    // 'returnPNR2nd',
    // 'returnCity2nd',
    // 'returnFlightName2nd',
    // 'returnFlightNumber2nd',
    // 'returnFlightDepartureTime2nd',
    // 'returnAirport2nd',


  ];

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    let isValid = true;
    let missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        missingFields.push(fieldDisplayNames[field]);  // Use display names
      }
    });

    return { isValid, missingFields };
  };


  const validate2ndForm = () => {
    let isValid2nd = true;
    let missingFields2nd = [];
    if (isChecked1st) {

      requiredforSecondFields.forEach((field) => {
        if (!formData[field]) {
          isValid2nd = false;
          missingFields2nd.push(fieldDisplayNames[field]);  // Use display names
        }
      });
    }

    return { isValid2nd, missingFields2nd };
  };


  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName') {
      // Capitalize the first letter of each word for companyName
      const uppercaseValue = value.toUpperCase();
      setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
    } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
      // Ensure only alphabetic characters for these fields
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
    }

    else if (name === 'mobileNumber') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    } else if (name === 'mobileNumber2nd') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    }

    else {
      // For other fields, just update the state as usual
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value) {

      if (name === 'emailAddress' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, [name]: '' })); // Optionally clear the field
      }
      if (name === 'emailAddress2nd' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, ["emailAddress2nd"]: '' })); // Optionally clear the field
      }
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Before sending to the API, ensure `designation` holds the correct value


    setFormData({
      ...formData, designation: formData.designation === "others"
        ? formData.otherDesignation
        : formData.designation
    });
    if (formData.roomOccupancy === "Double") {

      setFormData({
        ...formData, designation2nd: formData.designation2nd === "others"
          ? formData.otherDesignation2nd
          : formData.designation2nd
      });
    }



    // const { isValid2nd, missingFields2nd } = validate2ndForm();

    // const { isValid, missingFields } = validateForm();


    // if (!isValid) {
    //   alert(`Please fill the following required fields: ${missingFields.join(', ')}`);
    // } else if (
    //   isChecked1st && !isValid2nd
    // ) {
    //   alert(`Please fill the following required fields: ${missingFields2nd.join(', ')}`);

    // } else
    if (!isDateInRange(hotelCheckInDate) || !isDateInRange(hotelCheckOutDate)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
    } else if ((formData.ticketsDepartureBookedBy !== "Self" && formData.travelMode !== "Self") && (!isDateInRange(departureDate))) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date`);


    }
    else if (((formData.ticketsDepartureBookedBy2nd !== "Self" && formData.travelMode2nd !== "Self") && isChecked1st && !isChecked2nd) && !isDateInRange(departureDate2nd)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date of second person`);


    }
    else if ((formData.ticketsReturnBookedBy !== "Self" && formData.travelReturnMode !== "Self") && (!isDateInRange(returnFlightDate))) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date`);


    }




    else if ((formData.ticketsReturnBookedBy2nd !== "Self" && formData.travelReturnMode2nd !== "Self" && isChecked1st && !isChecked3rd) && !isDateInRange(returnFlightDate2nd)) {
      message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date of second person`);


    }


    //   else if (
    //     extractedPart === `gcc-retailer-registration-form` && !formData.country
    //   ) {
    //     message.error("Country is required for GCC Member");

    //   }
    //   // else if ((formData.ticketsDepartureBookedBy !== "Self" && formData.travelMode !== "Self") && (!formData.goaAirport)) {
    //   //   message.error("Please fill the required detail new");

    //   // }
    //   else if ((formData.ticketsDepartureBookedBy2nd !== "Self" && formData.travelMode2nd !== "Self") && isChecked1st && !isChecked2nd && (!formData.departureCity2nd || !formData.flightTrainName2nd || !formData.flightTrainNumber2nd || !formData.arrivalTimeGoa2nd || !formData.goaAirport2nd)) {
    //     message.error("Please fill the required detail new1");

    //   }
    //   else if ((formData.ticketsReturnBookedBy !== "Self" && formData.travelReturnMode !== "Self") && (!formData.returnCity || !formData.returnFlightName || !formData.returnFlightNumber || !formData.returnFlightDepartureTime || !formData.returnAirport)) {
    //     message.error("Please fill the required detail new2");

    //   }
    //   else if ((formData.ticketsReturnBookedBy2nd !== "Self" && formData.travelReturnMode2nd !== "Self") && isChecked1st && !isChecked3rd && (!formData.returnCity2nd || !formData.returnFlightName2nd || !formData.returnFlightNumber2nd || !formData.returnFlightDepartureTime2nd || !formData.returnAirport2nd)) {
    //     message.error("Please fill the required detail new3");
    //   }
    //   else if (formData.ticketsDepartureBookedBy === "Self" && formData.travelMode !== "Self" && !formData.departurePNR) {
    //     alert(`Please fill the following required fields: Departure PNR`);

    //   }
    //   else if (formData.ticketsDepartureBookedBy2nd === "Self" && formData.travelMode2nd !== "Self" && isChecked1st && !isChecked2nd && !formData.departurePNR2nd) {
    //     alert(`Please fill the following required fields: Departure PNR of Second Person`);

    //   }
    //   else if (formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode !== "Self" && !formData.returnPNR) {
    //     alert(`Please fill the following required fields: Return PNR`);

    //   }
    //   else if (formData.ticketsReturnBookedBy2nd === "Self" && formData.travelReturnMode2nd !== "Self" && isChecked1st && !isChecked3rd && !formData.returnPNR2nd) {
    //     alert(`Please fill the following required fields: Return PNR of Second Person`);

    //   }

    // else if (isChecked1st && (!isDateInRange(hotelCheckInDate2nd) || !isDateInRange(hotelCheckOutDate2nd))) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);

    // }

    else {
      console.log("hello finalDesignation", formData);

      // return
      axios.post(`${process.env.REACT_APP_API_END_POINT}/retailers-detail`, {
        data: formData,
        hotelCheckInDate,
        hotelCheckOutDate,
        departureDate,
        returnFlightDate,
        hotelCheckInDate2nd,
        hotelCheckOutDate2nd,
        departureDate2nd,
        returnFlightDate2nd,
        isChecked1st,
        isChecked2nd,
        isChecked3rd
      }).then((resData) => {
        // Show success message
        message.success("Form Submitted Successfully");
      })
        .catch((error) => {
          console.error("Error occurred while submitting the form:", error);
        })
        .finally(() => {
          // Reload the page after 1 second
          setTimeout(() => {
            window.location.reload(true);
          }, 500); // 500 milliseconds = 0.5 second
        });
    }
  };

  useEffect(() => {
    if (hotelCheckInDate) {
      console.log("hello", hotelCheckInDate, isDateInRange(hotelCheckInDate));
      if (!isDateInRange(hotelCheckInDate)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [hotelCheckInDate]);

  useEffect(() => {
    if (hotelCheckOutDate) {
      console.log("hello", hotelCheckOutDate, isDateInRange(hotelCheckOutDate));
      if (!isDateInRange(hotelCheckOutDate)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [hotelCheckOutDate]);


  useEffect(() => {
    if (departureDate) {
      console.log("hello", departureDate, isDateInRange(departureDate));
      if (!isDateInRange(departureDate)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [departureDate]);

  useEffect(() => {
    if (returnFlightDate) {
      console.log("hello", returnFlightDate, isDateInRange(returnFlightDate));
      if (!isDateInRange(returnFlightDate)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [returnFlightDate]);
  useEffect(() => {
    if (hotelCheckInDate2nd) {
      console.log("hello", hotelCheckInDate2nd, isDateInRange(hotelCheckInDate2nd));
      if (!isDateInRange(hotelCheckInDate2nd)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [hotelCheckInDate2nd]);

  useEffect(() => {
    if (hotelCheckOutDate2nd) {
      console.log("hello", hotelCheckOutDate2nd, isDateInRange(hotelCheckOutDate2nd));
      if (!isDateInRange(hotelCheckOutDate2nd)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [hotelCheckOutDate2nd]);


  useEffect(() => {
    if (departureDate2nd) {
      console.log("hello", departureDate2nd, isDateInRange(departureDate2nd));
      if (!isDateInRange(departureDate2nd)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [departureDate2nd]);

  useEffect(() => {
    if (returnFlightDate2nd) {
      console.log("hello", returnFlightDate2nd, isDateInRange(returnFlightDate2nd));
      if (!isDateInRange(returnFlightDate2nd)) {
        message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
      }
    }
  }, [returnFlightDate2nd]);

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy === "IGI") {

      setTravelModerarrivalArray(["Train", "Flight"])
    } else {
      setTravelModerarrivalArray(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy === "IGI") {

      setTravelModerDepartureArray(["Train", "Flight"])
    } else {

      setTravelModerDepartureArray(["Train", "Flight", "Self"])
    }
  }, [formData.ticketsReturnBookedBy])

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy2nd === "IGI") {
      setTravelModerarrivalArray2nd(["Train", "Flight"])

    } else {
      setTravelModerarrivalArray2nd(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy2nd])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy2nd === "IGI") {

      setTravelModerDepartureArray2nd(["Train", "Flight"])
    } else {
      setTravelModerDepartureArray2nd(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsReturnBookedBy2nd])
  // 
  useEffect(() => {
    if (extractedPart === `gcc-retailer-registration-form`) {
      setRepresentative_list(['Sadiye'])

    }
  }, [])
  const handleCheckboxChange = (data, setData) => {
    setData(!data); // Toggle the state
  };
  useEffect(() => {
    if (isChecked1st) {
      setIsChecked2nd(true)
      setIsChecked3rd(true)

    }
  }, [isChecked1st])
  useEffect(() => {

    if (formData.roomOccupancy === "Single") {
      setIsChecked1st(false)
      setIsChecked2nd(true)
      setIsChecked3rd(true)
      setShowBlock3rd(false)
      setShowBlock4th(false)

    }
  }, [formData.roomOccupancy])
  return (
    <>
      <div>
        <div className="text-center">
          <h4 className="form-heading">{extractedPart === `gcc-retailer-registration-form` ? "GCC Retailers Detail Form" : "Retailers Detail Form"}</h4>
        </div>

        <section>

          <div className="container">
            <div className="form-body-section">

              <div className="form-body">
                <form onSubmit={handleSubmit}>


                  <div className="row">
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        IGI Representative <font color="#FF0000">*</font>
                        {/* representative_list */}

                      </label>
                      <Select
                        className="my-2"
                        options={representative_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        required
                        value={
                          formData.representative
                            ? { value: formData.representative, label: formData.representative }
                            : null
                        }
                        onChange={(selectedOption) => setFormData({ ...formData, representative: selectedOption ? selectedOption.value : null })}


                        isSearchable
                        placeholder="Select Representative"
                      />
                      {/* <input
                        className="form-control my-2"
                        placeholder="IGI Representative"
                        value={formData.representative}
                        name="representative"
                        onChange={handleChange}
                      /> */}
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Name of the Company <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Name"
                        required
                        value={formData.companyName}

                        name="companyName"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room Occupancy
                        {/* <font color="#FF0000">*</font> */}
                      </label>
                      <Select
                        className="my-2"
                        required
                        options={occupancy_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          formData.roomOccupancy
                            ? { value: formData.roomOccupancy, label: formData.roomOccupancy }
                            : null
                        }
                        onChange={(selectedOption) => { setFormData({ ...formData, roomOccupancy: selectedOption ? selectedOption.value : null }); setShowBlock2nd(true); }}


                        isSearchable
                        placeholder="Select designation"
                      />

                    </div>
                    {showBlock2nd &&
                      <>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Name of the Person <font color="#FF0000">*</font>
                          </label>
                          <input
                            required
                            className="form-control my-2"
                            placeholder="Person Name"
                            value={formData.personName}

                            name="personName"
                            onChange={handleChange}
                          />
                        </div>


                        {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room No
                        <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Room No"
                        value={formData.roomNo}

                        name="roomNo"
                        onChange={handleChange}
                      />
                    </div> */}

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Mobile number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            required
                            placeholder="Mobile number"
                            value={formData.mobileNumber}
                            name="mobileNumber"
                            onChange={handleChange}
                          />

                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="emailAddress"
                            className="form-control my-2"
                            value={formData.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur} // Add onBlur event handler
                            placeholder="Email Address"
                          />
                          {/* <input
                        value={formData.emailAddress}

                        className="form-control my-2"
                        placeholder="Email Address"
                        name="emailAddress"
                        onChange={handleChange}
                      /> */}
                        </div>

                        {extractedPart === `gcc-retailer-registration-form` &&
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Country <font color="#FF0000">*</font>
                            </label>
                            <input
                              type="text"
                              name="country"
                              required

                              className="form-control my-2"
                              value={formData.country}
                              onChange={handleChange}
                              placeholder="Country"
                            />
                            {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                          </div>

                        }

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Designation <font color="#FF0000">*</font>
                          </label>


                          {/* Show the text field only if "others" is selected */}
                          {formData.designation === "others" ? (
                            <input
                              type="text"
                              required
                              className="form-control my-2"
                              placeholder="Enter your designation"
                              value={formData.otherDesignation}
                              onChange={(e) => handleOtherDesignationChange(e, '1st')}
                            />
                          ) :

                            (
                              <Select
                                className="my-2"
                                options={designationArray.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required={formData.designation !== "others" ? true : false}
                                value={
                                  formData.designation && formData.designation !== "others"
                                    ? { value: formData.designation, label: formData.designation }
                                    : null
                                }
                                onChange={(e) => handleSelectChange(e, '1st')}
                                isSearchable
                                placeholder="Select designation"
                              />
                            )}
                        </div>

                        {
                          formData.roomOccupancy === "Double" && <div className='my-3 ms-2' >
                            <label>
                              <input
                                type="checkbox"
                                checked={isChecked1st}
                                onChange={() => handleCheckboxChange(isChecked1st, setIsChecked1st)}
                              />
                              <span className='ms-3'>
                                Click Here to add Second Person
                              </span>
                            </label>
                            {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                          </div>
                        }

                        {isChecked1st &&
                          <div className="row">

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Name of the 2nd Person <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                placeholder="Person Name"
                                required={isChecked1st}
                                value={formData.personName2nd}

                                name="personName2nd"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Mobile number of 2nd Person <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                placeholder="Mobile number"
                                value={formData.mobileNumber2nd}
                                required={isChecked1st}

                                name="mobileNumber2nd"
                                onChange={handleChange}
                              />

                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Email Address  of 2nd Person
                              </label>
                              <input
                                type="email"
                                name="emailAddress2nd"
                                className="form-control my-2"
                                value={formData.emailAddress2nd}
                                onChange={handleChange}

                                onBlur={handleBlur} // Add onBlur event handler
                                placeholder="Email Address"
                              />
                              {/* <input
                             value={formData.emailAddress}
     
                             className="form-control my-2"
                             placeholder="Email Address"
                             name="emailAddress"
                             onChange={handleChange}
                           /> */}
                            </div>

                            {extractedPart === `gcc-retailer-registration-form` &&
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Country  <font color="#FF0000">*</font>
                                </label>
                                <input
                                  type="text"
                                  name="country"
                                  required

                                  className="form-control my-2"
                                  value={formData.country}
                                  onChange={handleChange}
                                  placeholder="Country"
                                />
                                {/* <input
                               value={formData.emailAddress}
       
                               className="form-control my-2"
                               placeholder="Email Address"
                               name="emailAddress"
                               onChange={handleChange}
                             /> */}
                              </div>

                            }

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Designation  of 2nd Person<font color="#FF0000">*</font>
                              </label>


                              {/* Show the text field only if "others" is selected */}
                              {formData.designation2nd === "others" ? (
                                <input
                                  type="text"
                                  required

                                  className="form-control my-2"
                                  placeholder="Enter your designation"
                                  value={formData.otherDesignation2nd}
                                  onChange={(e) => handleOtherDesignationChange(e, '2nd')}
                                />
                              ) :

                                (
                                  <Select
                                    className="my-2"
                                    options={designationArray.map((data) => ({
                                      value: data,
                                      label: data,
                                    }))}
                                    required={(isChecked1st && formData.designation !== "others") ? true : false}
                                    value={
                                      formData.designation2nd && formData.designation2nd !== "others"
                                        ? { value: formData.designation2nd, label: formData.designation2nd }
                                        : null
                                    }
                                    onChange={(e) => handleSelectChange(e, '2nd')}
                                    isSearchable
                                    placeholder="Select designation"
                                  />
                                )}
                            </div>
                          </div>
                        } </>
                    }

                  </div>
                  {showBlock2nd &&
                    <>

                      <div className="text-center">
                        <h4 className="form-heading">{"Hotel Detail"}</h4>
                      </div>
                      <hr />
                      <div className="row my-3">
                        <div className="form-group col-lg-6 col-md-6">
                          <label className="fw-bold">
                            Hotel Check-In Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckInDate} setSelectedDate={setHotelCheckInDate} lable={'Hotel Check-In Date (2nd Person)'} required={true}

                          />
                        </div>
                        <div className="form-group col-lg-6 col-md-6" onClick={() => setShowBlock3rd(true)}>
                          <label className="fw-bold">
                            Hotel Check-Out Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckOutDate} setSelectedDate={setHotelCheckOutDate} lable={'Hotel Check-Out Date (2nd Person)'}
                            required={true}

                          />
                        </div>

                      </div>
                    </>
                  }

                  {/* {formData.roomOccupancy === "Double" &&
                    <div className="row">
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Hotel Check-In Date of 2nd Preson <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          setDate={setHotelCheckInDate2nd} lable={'Hotel Check-In Date (2nd Person)'}

                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Hotel Check-Out Date of 2nd Preson <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          setDate={setHotelCheckOutDate2nd} lable={'Hotel Check-Out Date (2nd Person)'}

                        />
                      </div>

                    </div>}
                  <div className="text-center">
                    <h4 className="form-heading">{"Travel Detail"}</h4>
                  </div>
                  <hr /> */}

                  {
                    showBlock3rd &&

                    <>
                      <div className="text-center ">
                        <h4 className="form-heading">{"Arrival Detail"}</h4>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Tickets booked by
                            {/* <font color="#FF0000">*</font> */}
                          </label>
                          <Select
                            className="my-2"
                            options={ticket_by.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            required
                            value={
                              formData.ticketsDepartureBookedBy
                                ? { value: formData.ticketsDepartureBookedBy, label: formData.ticketsDepartureBookedBy }
                                : null
                            }
                            onChange={(selectedOption) => setFormData({ ...formData, ticketsDepartureBookedBy: selectedOption ? selectedOption.value : null })}


                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Travel Mode <font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={travelModerarrivalArray.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.travelMode
                                ? { value: formData.travelMode, label: formData.travelMode }
                                : null
                            }
                            required
                            onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null }); setShowBlock4th(true); }}

                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>
                        {extractedPart === `gcc-retailer-registration-form` &&
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Alloted Car <font color="#FF0000">*</font>
                            </label>
                            <input
                              type="text"
                              name="alloted_car"
                              className="form-control my-2"
                              required

                              value={formData.alloted_car}
                              onChange={handleChange}
                              onBlur={handleBlur} // Add onBlur event handler
                              placeholder="Alloted Car"
                            />
                            {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                          </div>

                        }

                        {formData.ticketsDepartureBookedBy === "Self" && formData.travelMode === "Self" ? null : formData.ticketsDepartureBookedBy === "Self" ? <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Arrival PNR <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.departurePNR}
                            required

                            placeholder="Arrival PNR"
                            name="departurePNR"
                            onChange={handleChange}
                          />
                        </div> : null}
                        {formData.ticketsDepartureBookedBy === "Self" && formData.travelMode === "Self" ? null :

                          <>

                            <div className="form-group col-lg-4 col-md-6">

                              <label className="fw-bold">
                                Arrival City <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.departureCity}
                                required
                                placeholder="Arrival City"
                                name="departureCity"
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Date of Arrival <font color="#FF0000">*</font>
                              </label>
                              <DatePickers
                                selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={true}

                              />
                            </div>


                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train name <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.flightTrainName}
                                required
                                placeholder="Flight/Train name"
                                name="flightTrainName"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Number <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                placeholder="Flight/Train Number"
                                value={formData.flightTrainNumber}
                                required
                                name="flightTrainNumber"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival time at Goa <font color="#FF0000">*</font>
                              </label>
                              <input
                                // type="text"
                                type="time"
                                className="form-control my-2"
                                placeholder="Arrival Time"
                                value={formData.arrivalTimeGoa}
                                name="arrivalTimeGoa"
                                required
                                onChange={handleChange}
                              />
                            </div>

                            {formData.travelMode === "Flight" && <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Goa Airport <font color="#FF0000">*</font>
                              </label>
                              {/* <input
                        className="form-control my-2"
                        value={formData.goaAirport}

                        placeholder="Goa Airport"
                        name="goaAirport"
                        onChange={handleChange}
                      /> */}
                              <Select
                                className="my-2"
                                options={airportListOfGoa.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required
                                value={
                                  formData.goaAirport
                                    ? { value: formData.goaAirport, label: formData.goaAirport }
                                    : null
                                }
                                onChange={(selectedOption) => setFormData({ ...formData, goaAirport: selectedOption ? selectedOption.value : null })}


                                isSearchable
                                placeholder="Choose One"
                              />

                            </div>}
                          </>
                        }


                      </div>
                      {
                        formData.roomOccupancy === "Double" && isChecked1st && <div >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked2nd}
                              onChange={() => handleCheckboxChange(isChecked2nd, setIsChecked2nd)}
                            />
                            <span className='ms-3'>

                              Add same details for {formData.personName2nd}
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>
                      }
                      <hr></hr>


                      {!isChecked2nd &&
                        <div className="row">
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Tickets booked by (2nd Person)
                              {/* <font color="#FF0000">*</font> */}
                            </label>
                            <Select
                              className="my-2"
                              options={ticket_by.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.ticketsDepartureBookedBy2nd
                                  ? { value: formData.ticketsDepartureBookedBy2nd, label: formData.ticketsDepartureBookedBy2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, ticketsDepartureBookedBy2nd: selectedOption ? selectedOption.value : null })}
                              required


                              isSearchable
                              placeholder="Choose one"
                            />

                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Travel Mode (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <Select
                              className="my-2"
                              options={travelModerarrivalArray2nd.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.travelMode2nd
                                  ? { value: formData.travelMode2nd, label: formData.travelMode2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, travelMode2nd: selectedOption ? selectedOption.value : null })}
                              required


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>
                          {extractedPart === `gcc-retailer-registration-form` &&
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Alloted Car (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                type="text"
                                name="alloted_car"
                                className="form-control my-2"
                                value={formData.alloted_car}
                                onChange={handleChange}
                                required

                                onBlur={handleBlur} // Add onBlur event handler
                                placeholder="Alloted Car"
                              />
                              {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                            </div>

                          }
                          {formData.ticketsDepartureBookedBy2nd === "Self" && formData.travelMode2nd === "Self" ? null : formData.ticketsDepartureBookedBy2nd === "Self" ?
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival PNR (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.departurePNR2nd}
                                required

                                placeholder="Arrival PNR"
                                name="departurePNR2nd"
                                onChange={handleChange}
                              />
                            </div> : null}

                          {
                            formData.ticketsDepartureBookedBy2nd === "Self" && formData.travelMode2nd === "Self" ? null :
                              <>

                                <div className="form-group col-lg-4 col-md-6">

                                  <label className="fw-bold">
                                    Arrival City (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    className="form-control my-2"
                                    value={formData.departureCity2nd}
                                    required

                                    placeholder="Arrival City"
                                    name="departureCity2nd"
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Date of Arrival (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <DatePickers
                                    selectedDate={departureDate2nd} setSelectedDate={setDepartureDate2nd} lable={'Date of Arrival'}
                                    required={true}

                                  />
                                </div>


                                <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Flight/Train name (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    className="form-control my-2"
                                    value={formData.flightTrainName2nd}
                                    required

                                    placeholder="Flight/Train name"
                                    name="flightTrainName2nd"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Flight/Train Number (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    className="form-control my-2"
                                    placeholder="Flight/Train Number"
                                    value={formData.flightTrainNumber2nd}
                                    required

                                    name="flightTrainNumber2nd"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Arrival time at Goa (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  <input
                                    // type="text"
                                    required

                                    type="time"
                                    className="form-control my-2"
                                    placeholder="Arrival Time"
                                    value={formData.arrivalTimeGoa2nd}
                                    name="arrivalTimeGoa2nd"
                                    onChange={handleChange}
                                  />
                                </div>
                                {formData.travelMode2nd === "Flight" &&
                                <div className="form-group col-lg-4 col-md-6">
                                  <label className="fw-bold">
                                    Goa Airport (2nd Person)<font color="#FF0000">*</font>
                                  </label>
                                  {/* <input
                          className="form-control my-2"
                          value={formData.goaAirport2nd}

                          placeholder="Goa Airport"
                          name="goaAirport2nd"
                          onChange={handleChange}
                        /> */}
                                  <Select
                                    className="my-2"
                                    options={airportListOfGoa.map((data) => ({
                                      value: data,
                                      label: data,
                                    }))}
                                    value={
                                      formData.goaAirport2nd
                                        ? { value: formData.goaAirport2nd, label: formData.goaAirport2nd }
                                        : null
                                    }
                                    onChange={(selectedOption) => setFormData({ ...formData, goaAirport2nd: selectedOption ? selectedOption.value : null })}
                                    required


                                    isSearchable
                                    placeholder="Choose One"
                                  />
                                </div>}
                              </>
                          }


                        </div>}


                    </>
                  }

                  {
                    showBlock4th && <>
                      <div className="text-center">
                        <h4 className="form-heading">{"Returning Detail"}</h4>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Tickets booked by
                            {/* <font color="#FF0000">*</font> */}
                          </label>
                          <Select
                            className="my-2"
                            options={ticket_by.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.ticketsReturnBookedBy
                                ? { value: formData.ticketsReturnBookedBy, label: formData.ticketsReturnBookedBy }
                                : null
                            }
                            onChange={(selectedOption) => setFormData({ ...formData, ticketsReturnBookedBy: selectedOption ? selectedOption.value : null })}
                            required

                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Travel Mode<font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={travelModerDepartureArray.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.travelReturnMode
                                ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                                : null
                            }
                            onChange={(selectedOption) => setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null })}

                            required
                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>
                        {extractedPart === `gcc-retailer-registration-form` &&
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Alloted Car (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <input
                              type="text"
                              name="alloted_car"
                              className="form-control my-2"
                              value={formData.alloted_car}
                              onChange={handleChange}
                              onBlur={handleBlur} // Add onBlur event handler
                              placeholder="Alloted Car"
                            />
                            {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                          </div>

                        }

                        {formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode === "Self" ? null : formData.ticketsReturnBookedBy === "Self" ?
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Returning PNR <font color="#FF0000">*</font>
                            </label>
                            <input
                              required
                              className="form-control my-2"
                              placeholder="Returning PNR"
                              value={formData.returnPNR}

                              name="returnPNR"
                              onChange={handleChange}
                            />
                          </div> : null}

                        {formData.ticketsReturnBookedBy === "Self" && formData.travelReturnMode === "Self" ? null :
                          <>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning City <font color="#FF0000">*</font>
                              </label>

                              <input
                                className="form-control my-2"
                                value={formData.returnCity}
                                required

                                placeholder="Returning City"
                                name="returnCity"
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Date <font color="#FF0000">*</font>
                              </label>
                              <DatePickers
                                required={true}
                                selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                              />
                            </div>


                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Name <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightName}
                                required

                                placeholder="Flight/Train Name"
                                name="returnFlightName"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Number <font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightNumber}
                                required

                                placeholder="Flight/Train Number"
                                name="returnFlightNumber"
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Time <font color="#FF0000">*</font>
                              </label>
                              <input
                                // type="text"
                                type="time"
                                required

                                className="form-control my-2"
                                placeholder="Returning Time"
                                value={formData.returnFlightDepartureTime}
                                name="returnFlightDepartureTime"
                                onChange={handleChange}
                              />
                            </div>


                            {formData.travelReturnMode === "Flight" &&
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning Airport <font color="#FF0000">*</font>
                              </label>
                              {/* <input
                                className="form-control my-2"
                                placeholder="Returning Airport"
                                value={formData.returnAirport}
                                name="returnAirport"
                                required

                                onChange={handleChange}
                              /> */}
                                  <Select
                                className="my-2"
                                options={airportListOfGoa.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required
                                value={
                                  formData.returnAirport
                                    ? { value: formData.returnAirport, label: formData.returnAirport }
                                    : null
                                }
                                onChange={(selectedOption) => setFormData({ ...formData, returnAirport: selectedOption ? selectedOption.value : null })}


                                isSearchable
                                placeholder="Choose One"
                              />
                            </div>}
                            
                            </>}

                      </div>
                      {
                        formData.roomOccupancy === "Double" && isChecked1st && <div >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked3rd}
                              onChange={() => handleCheckboxChange(isChecked3rd, setIsChecked3rd)}
                            />
                            <span className='ms-3'>

                              Add same details for {formData.personName2nd}
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>
                      }
                      <hr></hr>

                      {!isChecked3rd &&
                        <div className="row">
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Tickets booked by (2nd Person)
                              {/* <font color="#FF0000">*</font> */}
                            </label>
                            <Select
                              className="my-2"
                              options={ticket_by.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              required

                              value={
                                formData.ticketsReturnBookedBy2nd
                                  ? { value: formData.ticketsReturnBookedBy2nd, label: formData.ticketsReturnBookedBy2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, ticketsReturnBookedBy2nd: selectedOption ? selectedOption.value : null })}


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Travel Mode (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <Select
                              className="my-2"
                              required

                              options={travelModerDepartureArray2nd.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.travelReturnMode2nd
                                  ? { value: formData.travelReturnMode2nd, label: formData.travelReturnMode2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, travelReturnMode2nd: selectedOption ? selectedOption.value : null })}


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>
                          {extractedPart === `gcc-retailer-registration-form` &&
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Alloted Car (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                type="text"
                                name="alloted_car"
                                required

                                className="form-control my-2"
                                value={formData.alloted_car}
                                onChange={handleChange}
                                onBlur={handleBlur} // Add onBlur event handler
                                placeholder="Alloted Car"
                              />
                              {/* <input
                          value={formData.emailAddress}
  
                          className="form-control my-2"
                          placeholder="Email Address"
                          name="emailAddress"
                          onChange={handleChange}
                        /> */}
                            </div>

                          }
                          {formData.ticketsReturnBookedBy2nd === "Self" && formData.travelReturnMode2nd === "Self" ? null : formData.ticketsReturnBookedBy2nd === "Self" ?
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning PNR (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                required

                                placeholder="Returning PNR"
                                value={formData.returnPNR2nd}

                                name="returnPNR2nd"
                                onChange={handleChange}
                              />
                            </div> : null}
                          {formData.ticketsReturnBookedBy2nd === "Self" && formData.travelReturnMode2nd === "Self" ? null :
                            <>
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Returning City (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.returnCity2nd}
                                  required

                                  placeholder="Returning City"
                                  name="returnCity2nd"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train Date (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <DatePickers
                                  selectedDate={returnFlightDate2nd} setSelectedDate={setReturnFlightDate2nd} lable={'Date of Departure'}

                                />
                              </div>


                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train Name (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.returnFlightName2nd}
                                  required

                                  placeholder="Flight/Train Name"
                                  name="returnFlightName2nd"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train Number (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.returnFlightNumber2nd}
                                  required

                                  placeholder="Flight/Train Number"
                                  name="returnFlightNumber2nd"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Flight/Train Time (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  // type="text"
                                  type="time"
                                  required

                                  className="form-control my-2"
                                  placeholder="Returning Time"
                                  value={formData.returnFlightDepartureTime2nd}
                                  name="returnFlightDepartureTime2nd"
                                  onChange={handleChange}
                                />
                              </div>


                              {formData.travelReturnMode2nd === "Flight" &&
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Returning Airport (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                {/* <input
                                  className="form-control my-2"
                                  placeholder="Returning Airport"
                                  value={formData.returnAirport2nd}
                                  name="returnAirport2nd"
                                  onChange={handleChange}
                                  required

                                /> */}
                                 <Select
                                className="my-2"
                                options={airportListOfGoa.map((data) => ({
                                  value: data,
                                  label: data,
                                }))}
                                required
                                value={
                                  formData.returnAirport2nd
                                    ? { value: formData.returnAirport2nd, label: formData.returnAirport2nd }
                                    : null
                                }
                                onChange={(selectedOption) => setFormData({ ...formData, returnAirport2nd: selectedOption ? selectedOption.value : null })}


                                isSearchable
                                placeholder="Choose One"
                              />
                              </div>}
                            </>}

                        </div>


                      }
                    </>
                  }
                  <div className="text-center my-4">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </section >
      </div >
      <Footer />
    </>
  )
}

export default BuyerRegistrationForm
