import React from 'react'

const Footer = () => {
    return (
      <div className="footer">
        Copyright © {process.env.REACT_APP_ORGANIZER_NAME}. All Rights Reserved.
      </div>
    );
}

export default Footer