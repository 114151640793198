import React from 'react'
import { useNavigate } from "react-router-dom";
import Logo from "../assets/image/logo.png";

const CommonHeader = ({redirection}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="d-flex">
          <a>
            <img
              src={Logo}
              alt="logo-img"
              className="my-1 mx-5"
              style={{ height: "35px", width: "60px" }}
             
              onClick={() => navigate(redirection)}

          
            />
          </a>
          {/* <div className="d-flex mx-auto align-items-center">
          <span className="fw-bold fs-1" style={{ textAlign: "center" }}>
            {currentYear}
          </span>
        </div> */}
        </div>
    </div>
  )
}

export default CommonHeader
